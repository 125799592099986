export const Galleries: any = [
  {
    title: "Art Genesis",
    slogun: "The Future of Art is Here",
    id: "66e82f2f577f6f5bd4f14973",
    type: "gallery",
    discription:
      "Bridging the Gap Between Traditional and Digital Art Markets. Join Now and Showcase Your Art to a Global Audience",
    background: "https://api.artogenia.com/files?id=66d304750c63be0b71776324",
    thumbnail: "https://api.artogenia.com/files?id=66d6d2950c63be0b717764fa",
    link: "art-genesis",
    logo: "https://api.artogenia.com/files?id=66d492540c63be0b7177644a",
    sections: {
      section1: {
        title: "What is Art Genesis?",
        discription:
          "Art Genesis is a groundbreaking collaboration between Sareh Art Gallery and Artogenia Belgium, designed to revolutionize the way art is created, shared, and sold. By integrating blockchain technology and NFTs, we are merging traditional art markets with the limitless potential of digital platforms, enabling artists to reach global audiences and engage with collectors in new, exciting ways.",
      },
      section2: {
        title: "Why Choose Art Genesis?",
        contentType: "card",
        cards: [
          {
            icon: "https://api.artogenia.com/files?id=66d3050b0c63be0b71776326",
            title: "Global Artist Profiles",
            discription:
              "Create and manage your own detailed artist profile, showcasing your work to a global audience.",
          },
          {
            icon: "https://api.artogenia.com/files?id=66d305490c63be0b71776328",
            title: "Curated Online Exhibitions",
            discription:
              "Get featured in exclusive, professionally curated virtual exhibitions, increasing your exposure and visibility to potential buyers.",
          },
          {
            icon: "https://api.artogenia.com/files?id=66d3056d0c63be0b7177632a",
            title: "NFT Creation & Blockchain Integration",
            discription:
              "Secure your digital artworks with NFT technology, transparent transactions in the digital art space.",
          },
          {
            icon: "https://api.artogenia.com/files?id=66d3058e0c63be0b7177632c",
            title: "Sell on Top Platforms",
            discription:
              "Your art will be available for purchase on renowned platforms such as Artsy.net, reaching a network of international collectors.",
          },
        ],
      },
      section3: {
        title: "",
        time: "",
      },
    },
  },
  {
    title: "Photoacademy",
    slogun: "The Photo Academy Web3 Marketplace",
    id: "66e82f42577f6f5bd4f14975",
    type: "gallery",
    discription:
      "Join Photo Academy’s PhotoAcademy Contest! Capture the essence of ‘Urban Life’ from dynamic cityscapes to the hidden beauty of everyday moments. Submit your best shot and stand a chance to be featured and win exclusive prizes!",
    logo: "https://api.artogenia.com/files?id=66d496d60c63be0b71776470",
    background: "https://api.artogenia.com/files?id=66d303eb0c63be0b71776322",
    thumbnail: "https://api.artogenia.com/files?id=66d6d4380c63be0b717764fc",
    link: "photoacademy",
    sections: {
      section1: {
        title: "What is TPA Web3 Marketplace?",
        discription:
          'The <a href="https://thephotoacademy.com/" target="_blank">Photo Academy</a> Web3 Marketplace is a vibrant eco-system brought to you by TPA where the users can share their photos, turn them into NFT, and trade them (Auction or Fixed-price) while enjoying safe, secure, and advanced web3 features.',
      },
      section2: {
        imgUrl: "https://api.artogenia.com/files?id=66d4756b0c63be0b717763f8",
        contentType: "banner",
      },
      section3: {
        title: "",
        time: "",
      },
    },
  },
  {
    title: "First Painting Contest",
    slogun: "The 1st Artogenia Painting Contest 2024",
    id: "668f02d0b755b136a9e1f6b7",
    type: "contest",
    discription: "Win $3,000 Prize in Cash & $10,000 in Kind",
    background: "https://api.artogenia.com/files?id=66dee6f80c63be0b71776572",
    thumbnail: "https://api.artogenia.com/files?id=66ead5710c63be0b7177665e",
    link: "first-painting-contest",
    logo: "https://api.artogenia.com/files?id=66dedbcc0c63be0b71776570",
    sections: {
      section1: {
        title: "",
        discription: "",
      },
      section2: {
        title: "About the Contest",
        contentType: "big-card",
        cards: [
          {
            icon: "https://api.artogenia.com/files?id=66ead5b50c63be0b71776660",
            title: "Free to Enter",
            discription:
              "The contest is free to enter and the publication fee of $100 is applied after selection! We will create an NFT for each submitted artwork for free and then carefully evaluate all submissions and select the top 250 artworks that resonate most with our judges. ",
          },
          {
            icon: "https://api.artogenia.com/files?id=66ead5e60c63be0b71776662",
            title: "Contest Book",
            discription:
              "The top 250 selected artworks will be published in a digital contest book, available on well-known online libraries such as Amazon.All selected artworks will be published on Artogenia and will receive an international certificate. ",
          },
          {
            icon: "https://api.artogenia.com/files?id=66dee8200c63be0b71776578",
            title: "Top 10 on Artsy",
            discription:
              "The top 10 most exceptional artworks will be published on the prestigious online platform, Artsy. This is a significant opportunity to showcase your talent to established art collectors and galleries worldwide.",
          },
        ],
      },
      section3: {
        title: "NEXT Contest",
        time: "Sun Dec 12 2024 21:00:00 GMT+0000" ,
        theme: "linear-gradient(90deg, #324149 0%, #0F1015 100%)",
      },
      section5: {
        title: "Judging and Prizes Section",
        imgUrl: "https://api.artogenia.com/files?id=66def3120c63be0b7177657a",
        discription:
          "Our panel of esteemed art experts will carefully review all submissions and select winners based on originality, creativity, technical skill, and adherence to the contest theme.",
        cards: [
          {
            title: "Grand Prize: ",
            discription:
              "$2000 cash prize and artwork featured on Artogenia, Contest Book and Artsy.",
          },
          {
            title: "First Runner-up: ",
            discription:
              "$600 cash prize and artwork featured on Artogenia, Contest Book and Artsy.",
          },
          {
            title: "Second Runner-up: ",
            discription:
              "$400 cash prize and artwork featured on Artogenia, Contest Book and Artsy.",
          },
          {
            title: "Honorable Mentions: ",
            discription:
              "10 top selected selected artworks will be featured in Contest Book and Artsy.",
          },
        ],
      },
    },
  },
];
