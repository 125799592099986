import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { useSelector } from "react-redux";
import { useGetProfileDetailQuery } from "../../store/api/api";
import { biconomyContext } from "../../utils/context";
import { updateIsContest } from "./util";
import Loader from "../loader";
import { useNavigate } from "react-router-dom";
import ProfileProgress from "../profile-progress"
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [isLoading, setisLoading] = useState(false);
  const accessToken = useSelector<any>((state) => state.auth.accessToken);
  const { walletAddress } = useContext(biconomyContext);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [localStorage.getItem("contestModal")]);

  const {
    data,
    refetch: refetchProfile,
    isLoading: profileDataLoading,
  } = useGetProfileDetailQuery(
    {
      identifier: walletAddress?.toLowerCase(),
    },
    {
      skip: Boolean(!accessToken),
    }
  );
  const navigate = useNavigate();
  const closeModal = () => {
    localStorage.setItem("contestModal", "false");
    setIsModalOpen(false);
    onClose();
  };
  const disableScroll = () => {
    document.body.style.overflow = "hidden";
  };
  const [openProfileProgress, setOpenProfileProgress] = useState(false);
  const handelProfileModal = () => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data?.aboutMe, 'text/html');

    const extractedText = doc.body.textContent || '';
    if (
      (data?.firstName + data?.lastName + "")?.length > 1 &&
      data?.headline?.length > 1 &&
      data?.contactInfo?.phones[0]?.phone?.length > 1 &&
      extractedText?.length > 10
    )  {
      navigate("/mint");
    }else{
      disableScroll()
      setOpenProfileProgress(true)
     
    }
  };
  if (
    !isModalOpen ||
    !accessToken ||
    localStorage.getItem("contestModal") === "false"
  ) {
    return null;
  }

  return (
    <>
      <div className="modal">
        <div className="modal-content">
          <span className="close" onClick={closeModal}>
            &times;
          </span>

          <h3
            style={{
              textAlign: "center",
            }}
          >
            Are you interested to participate in <br />
            <b>Artogenia's Annual Painting Contest 2024 </b>
          </h3>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "15px",
            }}
          >
            <button
              className="attend-contest-btn"
              onClick={async () => {
                navigate("/profile/" + walletAddress);
                closeModal();
              }}
            >
              Select Artwork
            </button>

            <button
              className="attend-contest-btn"
              onClick={async () => {
                handelProfileModal();
                closeModal();
              }}
            >
              Create Artwork
            </button>
            <button
              className="attend-contest-btn"
              onClick={async () => {
                closeModal();
              }}
            >
              Not Interested
            </button>
          </div>
        </div>
      </div>

      {openProfileProgress ? <ProfileProgress  setClose={setOpenProfileProgress}  /> : null}
    </>
  );
};

export default Modal;
