import antiqueBg from "./antique-bg.webp";
import artBg from "./art-bg.webp";
import carpetBg from "./carpet-bg.webp";
import handcraftBg from "./handcraft-bg.webp";
import jewelryBg from "./jewelry-bg.webp";

export const MarketPlaces: any = [
  {
    name: "Art",
    slogun: "The world of art, in the frame of eternity",
    discription: "Ignite your Passion, Collect with Purpose",
    metaDiscription:
      "Artogania's Art Marketplace is a vibrant online hub where you can discover and purchase a diverse range of original artworks. From classic paintings to contemporary digital pieces, our platform showcases the talents of artists from around the world. Whether you're an experienced collector or simply looking to add a touch of creativity to your space, you'll find something to suit your taste. Browse through our curated collections, explore artist profiles, and connect with the creators behind the art.",
    marketTitle: "Curated Artworks",
    background: artBg,
    link: "/art/home",
    videoUrl:
      "https://embed-ssl.wistia.com/deliveries/d7c27f66a5daefe3416ea16346d18721411ce19a.bin?disposition=attachment&filename=Art+Website+Promo.mp4",
    footerUrl: "66c072a10c63be0b7177619c",
    sloganSvg: [],
  },
  {
    name: "Jewellery",
    slogun: "A Symphony of Brilliance, from Emeralds to Eternity",
    discription: "Jewelry that Captures the Soul of a Gem",
    metaDiscription:
      "Artogania's Jewelry Marketplace offers a stunning selection of handcrafted pieces that combine artistry and elegance. Discover unique designs crafted with precious metals and gemstones, each piece telling a story of the craftsmanship creativity, and artistic elegance of grand master Ali Jangi. From delicate necklaces and bracelets to bold earrings and rings, our jewelry collection is perfect for any occasion. Whether you're looking for a timeless classic or a statement piece, you'll find the perfect accessory to complement your personal style.",
    marketTitle: "",
    background: jewelryBg,
    link: "/jewellery/home",
    videoUrl: "https://artogenia.b-cdn.net/Website%20Jange%20V4.mp4",
    footerUrl: "66c071b20c63be0b7177619a",
    sloganSvg: [],
    banner: "https://api.artogenia.com/files?id=66e821df0c63be0b71776614",
    cards: [
      {
        imgUrl: "https://api.artogenia.com/files?id=66e82e9b0c63be0b7177661e",
        title: "Phoenix Flight",
        discription: "",
      },
      {
        imgUrl: "https://api.artogenia.com/files?id=66e82dda0c63be0b7177661a",
        title: "Zamen-e Ahoo",
        discription: "",
      },
      {
        imgUrl: "https://api.artogenia.com/files?id=66e59d8c0c63be0b717765ce",
        title: "Phoenix Flight",
        discription: "",
      },
      {
        imgUrl: "https://api.artogenia.com/files?id=66e82e500c63be0b7177661c",
        title: "PROUD FALCON",
        discription: "",
      },
    ],
  },

  {
    name: "Carpet",
    slogun: "Authentic silk Rugs",
    discription: "Own a piece of art, woven with stories",
    metaDiscription:
      "Artogania's Carpet Marketplace features a beautiful collection of hand-woven carpets from around the world. Each carpet is a masterpiece of craftsmanship, showcasing intricate patterns and vibrant colors. From traditional Persian rugs to contemporary designs, our carpets offer both functional beauty and a touch of cultural heritage. Discover the perfect carpet to add warmth and elegance to your home.",
    marketTitle: "Curated Artworks",
    background: carpetBg,
    link: "/carpet/home",
    videoUrl:
      "https://embed-ssl.wistia.com/deliveries/612604577aa92d98f9ddf62d8b42a496.bin?disposition=attachment&filename=Carpet+V2",
    footerUrl: "66c076e30c63be0b717761a0",
    sloganSvg: [],
  },
  {
    name: "Antique",
    slogun: "Own a piece of history",
    discription: "Treasures that last a lifetime.",
    metaDiscription:
      "Artogania's Antique Marketplace offers a treasure trove of vintage and antique items for collectors and enthusiasts. Discover a curated selection of furniture, decor, jewelry, and other unique finds from different eras. Each piece has a story to tell, reflecting the style and craftsmanship of its time. Whether you're searching for a rare collectible or a piece to add a touch of history to your home, our Antique Page is the perfect place to explore.",
    marketTitle: "Curated Artworks",
    background: antiqueBg,
    link: "/antique/home",
    videoUrl: "",
    footerUrl: "66c0715a0c63be0b71776196",
    sliders: [
      {
        file: "6628ee115b8f32ae02df2ce7",
        slogan:
          "Own a piece of history.<br/>Find antiques you'll <br/>love on Artogenia. ",
        device: "desktop",
      },
      {
        file: "6628ee305b8f32ae02df2ce9",
        slogan:
          "Invest in timeless<br/>beauty. Shop antiques<br/>on Artogenia.",
        device: "desktop",
      },
      {
        file: "6628ee3c5b8f32ae02df2ceb",
        slogan:
          "Treasures that last a<br/>lifetime. Discover <br/>antiques on Artogenia.",
        device: "desktop",
      },
      {
        file: "6628ef865b8f32ae02df2cf3",
        slogan:
          "Own a piece of history.<br/>Find antiques you'll <br/>love on Artogenia. ",
        device: "mobile",
      },
      {
        file: "6628ef935b8f32ae02df2cf5",
        slogan:
          "Invest in timeless<br/>beauty. Shop antiques<br/>on Artogenia.",
        device: "mobile",
      },
      {
        file: "6628ef5c5b8f32ae02df2cef",
        slogan:
          "Treasures that last a<br/>lifetime. Discover <br/>antiques on Artogenia.",
        device: "mobile",
      },
    ],
  },
  {
    name: "Handcraft",
    slogun: "Made with Love, Found on Artogenia",
    discription: "Ignite your Passion, Collect with Purpose ",
    metaDiscription:
      "Artogania's Handcrafts Marketplace celebrates the artistry and skill of traditional craftspeople. Explore a diverse range of handmade items, including ceramics, textiles, woodwork, and more. Each piece is a unique creation, often incorporating ancient techniques passed down through generations. Discover the beauty and authenticity of handcrafted items that will add character and charm to your home or serve as thoughtful gifts.",
    marketTitle: "",
    background: handcraftBg,
    link: "/handcraft/home",
    videoUrl: "",
    footerUrl: "66c0710a0c63be0b71776194",
    sliders: [
      {
        file: "662ca11e5b8f32ae02df2d13",
        slogan: "Made with love,<br/> found on Artogenia.",
        device: "desktop",
      },
      {
        file: "662ca1325b8f32ae02df2d15",
        slogan: "Handcrafted<br/>treasures, waiting<br/> to be discovered.",
        device: "desktop",
      },
      {
        file: "662ca14d5b8f32ae02df2d17",
        slogan:
          "Own a piece of <br/>passion. Explore<br/>handcrafts on <br/>Artogenia.",
        device: "desktop",
      },
      // mobile
      {
        file: "662e52785b8f32ae02df2d35",
        slogan: "Made with love,<br/> found on Artogenia.",
        device: "mobile",
      },
      {
        file: "662e52965b8f32ae02df2d37",
        slogan: "Handcrafted<br/>treasures, waiting<br/> to be discovered.",
        device: "mobile",
      },
      {
        file: "662e52aa5b8f32ae02df2d39",
        slogan:
          "Own a piece of <br/>passion. Explore<br/>handcrafts on <br/>Artogenia.",
        device: "mobile",
      },
    ],
  },
  {
    name: "Academy",
    slogun:
      "Unlock Your Potential: Explore Our Courses for a Brighter Tomorrow",
    discription: "Ignite your Passion, Collect with Purpose ",
    metaDiscription:
      "Artogania's Academia Page is a dedicated space for training the next generation of artists. Explore a curated collection of art training materials that can be used for educational purposes, exhibitions, and scholarly research. From historical paintings and sculptures to contemporary installations, our Academia Page offers a valuable resource for students, professors, and cultural institutions. Discover the perfect pieces to enhance your academic endeavors and inspire creativity.",
    marketTitle: "",
    background: handcraftBg,
    link: "/handcraft/home",
    videoUrl:
      "https://embed-ssl.wistia.com/deliveries/39dbc98364d5bae4876721e95e147164.bin?disposition=attachment&filename=academia",
    sloganSvg: [],
    footerUrl: "66c070c60c63be0b71776192",
  },
];
